import React from "react";
import { TagGroup, Tag, Avatar } from "rsuite";
import { Link } from "react-router-dom";

const OfficerPayout = ({ groupedShifts, providerCompanyId, job }) => {
  return (
    <div className="shifts_card ">
      <h2 className="shifts_title">Officer Payouts</h2>
      {Object.entries(groupedShifts).length > 0 ? (
        Object.entries(groupedShifts).map(([officerName, details]) => (
          <div key={officerName} className="mt-3">
            <div className="d-flex flex-column  p-2 ">
              <div className="d-flex justify-content-between align-items-center">
                {/* Left Side: Avatar, Name, and Email */}
                <div className="d-flex align-items-center">
                  <Avatar
                    circle
                    size="sm"
                    src={
                      details.profilePicture || "https://via.placeholder.com/40"
                    }
                    style={{
                      background: "#000",
                      marginRight: "12px",
                      flexShrink: 0,
                    }}
                  />
                  <div className="completed-offers-info">
                    <p className="completed-offers-name">{officerName}</p>
                    <p className="completed-offers-email">{details.email}</p>
                  </div>
                </div>

                <span
                  className={`completed-offers-badge completed-offers-${details.status}`}
                >
                  {details.status.charAt(0).toUpperCase() +
                    details.status.slice(1)}
                </span>
              </div>

              <div className="completed-offers-container p-2">
                <span className="completed-offers-amount mr-1">
                  ${details.totalPayout.toFixed(2)}
                </span>

                <button className="completed-offers-manage-btn mr-1">Pay</button>

                <Link
                  //to={`/provider-company/${providerCompanyId}/jobs/${job?._id}?view=recommended`}
                  to={`/provider-company/${providerCompanyId}/jobs/${job._id}/offer`}
                  className="completed-offers-link"
                >
                  Offer →
                </Link>

              </div>
            </div>

            <table className="completed-offers-table">
              <tbody id="data-body">
                {details.shifts.map((shift, index) => (
                  <tr key={index} className="completed-offers-row">
                    {/* Shift Date */}
                    <td className="completed-offers-time">
                      {new Date(shift.date).toLocaleDateString("en-US", {
                        weekday: "short",
                        month: "short",
                        day: "numeric",
                      })}
                    </td>

                    {/* Shift Time */}
                    <td className="completed-offers-time">
                      {new Date(shift.startTime).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                      {" - "}
                      {new Date(shift.endTime).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </td>

                    {/* Shift Payout */}
                    <td className="completed-offers-payout ">
                      <span>
                        {shift.payout
                          ? `$${shift.payout.toFixed(2)}`
                          : "Not Calculated"}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <hr />
          </div>
        ))
      ) : (
        <p style={{ textAlign: "center" }}>No completed shifts.</p>
      )}
    </div>
  );
};

export default OfficerPayout;
