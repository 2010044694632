import React from 'react';
import { Steps, Button , Panel , Col , Checkbox, InputNumber } from 'rsuite';
import { createRevisedOffer , createFinalOffer } from '../../../../../../../services/staffingService';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { Link } from 'react-router-dom';


const NegotiationSteps = ({ offer , setOffer, negotiations, setNegotiations , offerId , providerCompanyId, jobId }) => {

    const[customerRatePercentage, setCustomerRatePercentage] = React.useState(0.15);
    // const [offerPrice, setOfferPrice] = React.useState(offer.jobId.hourlyPayRate*(1-customerRatePercentage));

    const [offerPrice, setOfferPrice] = React.useState(() => 
        offer ? offer.jobId.hourlyPayRate * (1 - customerRatePercentage) : 0
    );

    React.useEffect(() => {
        if (offer?.jobId?.hourlyPayRate) {
            const customerHourlyRate = offer.jobId.hourlyPayRate;
            setOfferPrice(customerHourlyRate * (1 - customerRatePercentage));
        }
    }, [offer, customerRatePercentage]);
    
    
    const handleRevisedOffer = async () => {
        let newRatePercentage = 0.15 ;
        let revisedProviderHourlyRate = offer.jobId.hourlyPayRate *(1-newRatePercentage) 
        const { data } = await createRevisedOffer(providerCompanyId, offer._id, revisedProviderHourlyRate);
        setOffer(data.offer);
        setNegotiations(data.offer.negotiations);

    };

    const handleChange = (value) => {
        setOfferPrice(value);
        setCustomerRatePercentage(Math.round((offer.jobId.hourlyPayRate - offerPrice) / offer.jobId.hourlyPayRate * 100))
    };

    function formatTimestamp(timestamp) {
        const date = new Date(timestamp);
        return `${date.toLocaleDateString()} at ${date.toLocaleTimeString()}`;
    }
    

    return (
        <>
        {
            Object.keys(offer).length > 0 && Object.keys(negotiations).length > 0 && 
            <Steps current={offer.overallActiveStep>0 ? 1 : 0} vertical>
                <Steps.Item 
                    title="Active Offer"
                    description={
                        <>
                            <div >
                                <div className="">

                                    Offer at {Math.round(negotiations.customerRatePercentage)}% Margin<br />
                                    Hourly Rate Offered: ${negotiations.providerHourlyRate}<br />
                                    {/* Offer Status: {
                                        offer.status === 'accepted' ? <span style={{ color: 'green' }}>Offer Accepted</span> :
                                        offer.status === 'rejected' ? <span style={{ color: 'red' }}>Offer Rejected - {negotiations.rejectionReason} </span> :
                                        <>{offer.status}</>
                                    } */}
                                </div>
                                <div className="mt-2 " style={{color: '#3D4784' }}>
                                    {/* {negotiations.status === 'rejected' && offer.child?.exists === false && (
                                        <Col>
                                            <Panel bordered style={{width:'400px', backgroundColor:'#FCFDFE'}}>
                                                <div className="mt-2"> <TipsAndUpdatesIcon style={{color: '#FFEB3B'}} className="mr-1"/>  
                                                    Send revised offer at {`${Math.round((offer.jobId.hourlyPayRate - offerPrice) / offer.jobId.hourlyPayRate * 100)}% margin`} 
                                                </div>
                                                <div className="d-flex mt-2" style={{ marginLeft: '2.3em'}}>
                                                    <div> Set New Hourly Rate </div> 
    
                                                    <div className="ml-2" id="offer-stepper-price-bidding"> 
                                                        <InputNumber 
                                                            size="xs" 
                                                            // placeholder={rate}
                                                            value={offerPrice}
                                                            onChange={(value) => handleChange(value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mt-2" style={{ marginLeft: '2.3em'}} > <Button style={{fontWeight:'600', color: '#db2777'}} onClick={handleRevisedOffer}> Make Offer </Button>  </div>
                                            </Panel>
                                       </Col>
                                    )}
                                    {negotiations.status === 'rejected' && offer.child?.exists && (
                                         <div className="mt-2 " style={{color: '#3D4784'}}>                                    
                                            <Col>
                                                <Panel bordered style={{width:'400px', backgroundColor:'#FCFDFE'}}>  
                                                    <div className="mt-2"> Revised offer already exists for this offer. </div>
                                                    <div className="mt-2" > 
                                                        <Link to={`/provider-company/${providerCompanyId}/jobs/${offer.jobId._id}/offer/${offer.child.childOfferId}`}>                                                    
                                                            <Button style={{fontWeight:'600', color: '#db2777'}}> Active Offer </Button>  
                                                        </Link>
                                                    </div>
                                                </Panel>
                                            </Col>
                                        </div>
                                    )} */}
                                    { (offer.status === 'rejected'|| offer.status ==='expired') && offer.child?.exists === false && (
                                        <Col>
                                            <Panel bordered style={{width:'400px', backgroundColor:'#FCFDFE'}}>
                                                <div className="mt-2"> <TipsAndUpdatesIcon style={{color: '#FFEB3B'}} className="mr-1"/>  
                                                    Send revised offer at {`${Math.round((offer.jobId.hourlyPayRate - offerPrice) / offer.jobId.hourlyPayRate * 100)}% margin`} 
                                                </div>
                                                <div className="d-flex mt-2" style={{ marginLeft: '2.3em'}}>
                                                    <div> Set New Hourly Rate </div> 
    
                                                    <div className="ml-2" id="offer-stepper-price-bidding"> 
                                                        <InputNumber 
                                                            size="xs" 
                                                            // placeholder={rate}
                                                            value={offerPrice}
                                                            onChange={(value) => handleChange(value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mt-2" style={{ marginLeft: '2.3em'}} > <Button style={{fontWeight:'600', color: '#db2777'}} onClick={handleRevisedOffer}> Make Offer </Button>  </div>
                                            </Panel>
                                       </Col>
                                    )}
                                    {offer.status === 'rejected' && offer.child?.exists && (
                                         <div className="mt-2 " style={{color: '#3D4784'}}>                                    
                                            <Col>
                                                <Panel bordered style={{width:'400px', backgroundColor:'#FCFDFE'}}>  
                                                    <div className="mt-2"> Revised offer already exists for this offer. </div>
                                                    <div className="mt-2" > 
                                                        <Link to={`/provider-company/${providerCompanyId}/jobs/${offer.jobId._id}/offer/${offer.child.childOfferId}`}>                                                    
                                                            <Button style={{fontWeight:'600', color: '#db2777'}}> Active Offer </Button>  
                                                        </Link>
                                                    </div>
                                                </Panel>
                                            </Col>
                                        </div>
                                    )}
    
                                </div>
                            </div>
                        </>
                    }
                />
    
                {/* <Steps.Item
                    title="Revised Offer"
                    description={
                        negotiations.negotiationActiveStep >= 1 && (
                            <>
                                <div style={{ marginTop: 10, fontSize: 16 }}>
                                    Offer at {negotiations.steps[1].customerRatePercentage}% of customer rate<br />
                                    Amount: ${negotiations.steps[1].amount}<br />
                                    Status: {
                                        negotiations.steps[1].status === 'confirmed' ? <span style={{ color: 'green' }}>Offer Confirmed</span> :
                                        negotiations.steps[1].status === 'rejected' ? <span style={{ color: 'red' }}>Offer Rejected</span> :
                                        <span>Pending</span>
                                    }
                                    <div className="mt-2">
                                    {negotiations.negotiationActiveStep === 1 && negotiations.steps[1].status === 'rejected' && (
                                        <Col md={9} sm={12}>
                                            <Panel bordered>             
                                                <div className=""> Send final offer at 10% of customer price  </div>
                                                <div className=""> Final Price : ${customerRate*(1-0.10)} </div>
                                                <Checkbox  style={{marginLeft:'-10px'}} defaultChecked > Confirm Manager & Customer Approval (if required) </Checkbox>
                                                <div className="mt-2"> <Button onClick={handleFinalOffer}>Send </Button>  </div>
                                            </Panel>
                                        </Col>
                                    )}
                                    </div>
                                </div>
                            </>
                        )
                    }
                />
    
                <Steps.Item
                    title="Final Offer"
                    description={
                        negotiations.negotiationActiveStep >= 2 && (
                        // negotiations.steps[1].status !== 'pending' && (
                            <>
                                <div style={{ marginTop: 10, fontSize: 16 }}>
                                    Offer at {negotiations.steps[2].customerRatePercentage}% of customer rate<br />
                                    Final offer amount: ${negotiations.steps[2].amount}<br />
                                    Status: {
                                        negotiations.steps[2].status === 'confirmed' ? <span style={{ color: 'green' }}>Offer Confirmed</span> :
                                        negotiations.steps[2].status === 'rejected' ? <span style={{ color: 'red' }}>Offer Rejected</span> :
                                        <span>Pending (Expiry Timestamp - {formatTimestamp(negotiations.steps[2].expiredAt)})</span>
                                    }
                                    <br />{negotiations.steps[2].details}
                                    {negotiations.negotiationActiveStep === 2 && negotiations.steps[2].status === 'rejected' && (
                                        <Col md={9} sm={12}>
                                            <Panel bordered>             
                                                <div className="" style={{ color: 'red' }}> Negotiation limit reached. Offer Invalid.  </div>
                                            </Panel>
                                        </Col>
                                    )}
                                </div>
                            </>
                        )
                    }
                />
                */}
            </Steps> 

        }
        </>
    );
}

export default NegotiationSteps;
