import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import OfferStepper from "./stepper/OfferStepper";
import { getOffer } from "../../../../../../services/staffingService";
import "./offer.css"; // Assuming CSS is external
import JobStreetView from "./jobStreetView";
import { AvatarGroup, Avatar, Badge, Button } from "rsuite";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link } from "react-router-dom";
import DistanceMap from "../../../../officer/dashboard/DistanceMap";
// import DistanceMap from "./DistanceMapbox" ;
import DashboardSidebar from "../../../../../common/dashboardSidebar";

function Offer({ offerId, providerCompanyId, jobId }) {
//   const { offerId, providerCompanyId, jobId } = useParams();
  const [offer, setOffer] = useState({});
  const [negotiations, setNegotiations] = useState({});
  const [job, setJob] = useState({});
  const [allOffers, setAllOffers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [lastUpdated, setLastUpdated] = useState("");
  const [staff, setStaff] = useState({});

  useEffect(() => {
    fetchOfferData();
  }, [offerId]);

  async function fetchOfferData() {
    setLoading(true);
    try {
      const { data } = await getOffer(providerCompanyId, offerId);
      setOffer(data.offer);
      setNegotiations(data.offer.negotiations);
      setLastUpdated(new Date().toLocaleString());
      setJob(data.job);
      setAllOffers([...data.all_offers]);
      setStaff(data.staff);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching offer data:", error);
      setError("Failed to fetch data");
      setLoading(false);
    }
  }

  function formatJobDateTime(job) {
    const date = new Date(job.customFields.workOrderDate);
    const friendlyDate = date.toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    const [startTime, endTime] = job.customFields.shiftHours
      .split("-")
      .map((time) => `${parseInt(time)} Hrs`);
    return `${job.customFields.totalStaffsRequired} Staffs required on ${friendlyDate} between ${startTime} to ${endTime}`;
  }

  return (
    <>
      {/* <DashboardSidebar /> */}

      {loading && <div className="offer-progress-loading">Loading...</div>}
      {error && <div className="offer-progress-error">{error}</div>}
      {Object.keys(offer).length > 0 && Object.keys(staff).length > 0 && (
        <>
          {/* <div className="offer-staffing-progress-details">
    <Avatar
      circle
      bordered
      size="xs"
      alt="Active Staff 1"
      src={`https://i.pravatar.cc/150?u=2`}
      className="mr-1"
    />
    <div className="staff-details">
      <p>{staff && `${staff.firstName} ${staff.lastName}`}</p>
      <p>Offer ID: {offerId}</p>
      <p>Updated: {lastUpdated}</p>
    </div>
  </div> */}

          <div className="offer-map-container">
            <div className="offer-stepper ml-4">
              <OfferStepper
                offer={offer}
                negotiations={negotiations}
                setNegotiations={setNegotiations}
                setOffer={setOffer}
                offerId={offerId}
                providerCompanyId={providerCompanyId}
                jobId={jobId}
              />
            </div>
            <div className="distance-map">
              <DistanceMap
                source={staff.geoLocation}
                destination={job.geoLocation}
              />
              {/* <h5 className="mt-4">
                {" "}
                Marvin is 10 miles away from job location

              </h5>
                <p>Offer ID: {'66f137d7c9aefb965a2dc9dd'}</p>
                        <p>Updated: {'lastUpdated'}</p> */}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Offer;

/*


show offers all 



                     {
                            allOffers.length >= 1 &&
                            <div className="offer-staffing-status mt-2">
                                <div className="offer-staffing-status-item">
                                    <Badge content={ allOffers.length } color="green"><span className="pr-2">Active offers sent to other security staffs nearby </span></Badge>
                                    <AvatarGroup className="mt-2" spacing={6}>
                                        {
                                            allOffers.map((offer, index) => {
                                                return <Avatar  style={offer._id === offerId ? { border: '2px solid green' } : {}} key={index} circle src={`https://i.pravatar.cc/150?u=${index}`} alt="Active Staff 3" />
                                                }   
                                            )
                                        }
                                    </AvatarGroup>
                                </div>
                            </div>
                        } */
