import React, { useState } from "react";
import { Tabs } from "rsuite";
import { Link } from "react-router-dom";

import DashboardSidebar from "../../../../common/dashboardSidebar";
import { getJobMetadata } from "../../../../../services/staffingService";
import { useParams } from "react-router-dom";
import "./status/offer.css";
import Header from "../../../../common/header";

import OfficerListing from "./recommendations/listing";
import OfferStatus from "./status/status";

const Assign = () => {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("1"); // Default to tab "1"

  const [eventKey, setEventKey] = useState("1");
  const [activeCardIndex, setActiveCardIndex] = useState(0);
  const [showSideDrawer, setShowSideDrawer] = useState(true);
  const [recommendationsCount, setRecommendationsCount] = useState(0);
  const [officersCount, setOfficersCount] = useState(0)
  const [offersMetadata, setOffersMetadata] = useState([]);
  const [selectedOfferId, setSelectedOfferId] = useState(null);
  const { providerCompanyId, jobId } = useParams();
  
  let view= 'recommended';
  // let view= 'closest';
  const [listViewType, setListViewType] = useState(view === 'recommended' ? 'recommended' : 'closest');

  React.useEffect(() => {
    // fetchJobs()
    // total recommendations count
    fetchJobAndOfferMetadata();
    // offers with name of a resource
  }, []);

  async function fetchJobAndOfferMetadata() {
    setLoading(true);
    const resp = await getJobMetadata(providerCompanyId, jobId);
    // setRecommendationsCount(resp.data.totalRecommendations);
    const offers = resp.data.offers || [];
    setOffersMetadata([...offers]);
    setSelectedOfferId(offers.length > 0 ? offers[0]._id : null);
    setLoading(false);
  }

  const handleOfferSelection = (offerId) => {
    setSelectedOfferId(offerId);
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <>
      <div className="dashboard">
        <DashboardSidebar isOpen={showSideDrawer} />
        <div className="dashboard_content">
          <Header
            isOpen={showSideDrawer}
            setIsOpen={setShowSideDrawer}
            breadcrumb={"Job > Offers"}
          />

          <div className="staffing-new-assignments-body" id="scrollBar">
            <div className="staffing-new-assignments_inner">
              <div className="position-relative">
                <Tabs
                  activeKey={activeTab}
                  onSelect={handleTabChange}
                  appearance="subtle"
                  className="tabs_content"
                >
                  <Tabs.Tab
                    eventKey="1"
                    title={
                      <>
                        {" "}
                        Officers{" "}
                        <span
                          className={`ml-1 circle-number ${
                            activeCardIndex == 0 ? "active" : ""
                          }`}
                        >
                          {" "}
                          {officersCount}{" "}
                        </span>{" "}
                      </>
                    }
                  >
                      <OfficerListing
                        providerCompanyId={providerCompanyId}
                        jobId={jobId}
                        setListViewType={setListViewType}
                        listViewType={listViewType}
                        setSelectedOfferId={setSelectedOfferId}
                        handleTabChange={handleTabChange}
                        setActiveTab={setActiveTab}
                        setOfficersCount={setOfficersCount}
                        officersCount={officersCount}
                        fetchJobAndOfferMetadata={fetchJobAndOfferMetadata}
                      />
                   
                  </Tabs.Tab>

                  <Tabs.Tab
                    eventKey="2"
                    title={
                      <>
                        {" "}
                        Shift Offers{" "}
                        <span
                          className={`ml-1 circle-number ${
                            activeCardIndex == 1 ? "active" : ""
                          }`}
                        >
                          {" "}
                          {offersMetadata.length}{" "}
                        </span>{" "}
                      </>
                    }
                    
                  >
                    {offersMetadata.length === 0 && "No Offers sent"}
                    {offersMetadata.length > 0 && (
                      <>
                        <div className="offer-status-profiles-outer-container">
                          <div className="offer-status-profiles-container">
                            {offersMetadata.map((offer, index) => {
                              const user = offer.provider_resource_id.user_id;
                              const profileName = `${user?.firstName} ${user?.lastName}`;
                              const isDisputed =
                                offer.status.toLowerCase() === "disputed";
                              const displayStatus = isDisputed
                                ? `${offer.status} - Action Needed`
                                : offer.status;

                              return (
                                <React.Fragment key={index}>
                                  <div
                                    className="offer-status-profile-card"
                                    onClick={() =>
                                      handleOfferSelection(offer._id)
                                    }
                                  >
                                    <div
                                      className={`offer-status-profile-circle ${
                                        offer._id === selectedOfferId
                                          ? "offer-status-active"
                                          : ""
                                      }`}
                                    >
                                      {user?.profilePictureUrl && (
                                        <img
                                          src={user?.profilePictureUrl}
                                          alt={profileName}
                                          className="offer-status-profile-image"
                                        />
                                      )}
                                    </div>
                                    <div className="offer-status-profile-name">
                                      {profileName}
                                    </div>
                                    <div
                                      className={`offer-status-text ${offer.status.toLowerCase()}`}
                                    >
                                      {displayStatus}
                                    </div>
                                  </div>
                                </React.Fragment>
                              );
                            })}
                          </div>
                        </div>

                        {selectedOfferId && (
                          <OfferStatus
                            offerId={selectedOfferId}
                            providerCompanyId={providerCompanyId}
                            jobId={jobId}
                          />
                        )}
                      </>
                    )}
                  </Tabs.Tab>
                </Tabs>
              </div>
            </div>
          </div>

          {loading && <div className="loader">Loading...</div>}
        </div>
      </div>
    </>
  );
};

export default Assign;
