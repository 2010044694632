import { Card, CardContent, Button } from "@mui/material";
import { useState } from "react";
import { Drawer, SelectPicker, Steps } from 'rsuite';


export default function ShiftSchedule({ /*job ,*/ isOpen, setIsOpen, getByItem}) {

  const [job] = useState({
    customer: "Amazon - East Boston",
    estimatedPay: "$453",
    staffs: [
      {
        name: "Jerry Ross",
        role: "Front End Developer",
        image: "/images/jerry.jpg",
        shifts: [
          { date: "June 5", time: "8 AM - 10 AM", status: "Completed", color: "green" },
          { date: "June 6", time: "6 AM - 10 PM", status: "Completed", color: "green" },
        ],
      },
      {
        name: "Sara Collins",
        role: "Executive Admin",
        image: "/images/sara.jpg",
        shifts: [
          { date: "June 5", time: "9 PM - Completed", status: "Completed", color: "green" },
          { date: "June 6", time: "10 PM - 12 AM", status: "Resolved", color: "blue" },
        ],
      },
    ],
  });

  const [staffShifts] = useState([
    {
      name: "Jerry Ross",
      role: "Front End Developer",
      image: "/images/jerry.jpg",
      shifts: [
        { date: "June 5", time: "8 AM - 10 AM", status: "Completed", color: "#4CAF50" },
        { date: "June 6", time: "6 AM - 10 PM", status: "Completed", color: "#4CAF50" },
      ],
    },
    {
      name: "Sara Collins",
      role: "Executive Admin",
      image: "/images/sara.jpg",
      shifts: [
        { date: "June 5", time: "9 PM - Completed", status: "Completed", color: "#4CAF50" },
        { date: "June 6", time: "10 PM - 12 AM", status: "View Offers", color: "#FFC107" },
      ],
    },
  ]);



  return (  <Drawer open={isOpen} onClose={() => setIsOpen(false)} className="side_drawer">
  <Drawer.Body>
  <div className="p-6 space-y-6">
      {/* Merged View: Customer, Staff & Shifts */}
      <Card variant="outlined" className="p-4">
        <div className="grid grid-cols-3 gap-4">
          {/* Customer Column with CTA and Estimated Pay */}
          <div className="border-r pr-4 flex flex-col justify-between">
            <div>
              <p className="text-gray-600">{job.customer}</p>
              <p className="text-gray-800 text-lg font-semibold mt-2">Estimated Pay: {job.estimatedPay}</p>
            </div>
            <Button variant="contained" color="primary" className="mt-4">Request Payment</Button>
          </div>
          
          {/* Staff & Shifts Column */}
          <div className="col-span-2">
            {job.staffs.map((staff, index) => (
              <Card key={index} variant="outlined" className="mb-6 p-4 bg-white rounded-lg">
                <CardContent>
                  <div className="flex items-center space-x-4 mb-2">
                    <img src={staff.image} alt={staff.name} className="w-10 h-10 rounded-full" />
                    <div>
                      <p className="font-medium text-gray-700">{staff.name}</p>
                      <p className="text-gray-500 text-sm">{staff.role}</p>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 gap-2">
                    {staff.shifts.map((shift, idx) => (
                      <div
                        key={idx}
                        className="p-2 rounded-lg text-white text-sm"
                        style={{ backgroundColor: shift.color }}
                      >
                        {shift.date}: {shift.time} - {shift.status}
                      </div>
                    ))}
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </Card>
    </div>
    </Drawer.Body>
    </Drawer>
  );
}
