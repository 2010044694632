import React from "react";
import "../../../../styles/unassignedListView.css";
import { Badge } from "@mui/material";
import { useEffect, useState } from "react";
import SideDrawer from "./sideDrawer";
import Send from "../../../../../_asset/img/send-2.svg";
import JobStreetView from "../../offers/status/jobStreetView";
import { Link, useParams } from "react-router-dom";
import { Button, Stack } from "rsuite";

import { getJobDisputedOffers } from "../../../../../../services/staffingService";

const UnassignedListView = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [getByItem, setGetByItem] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const { providerCompanyId } = useParams();
  const [disputedOffers, setDisputedOffers] = useState([]);

  useEffect(() => {
    if (!open) {
      setGetByItem(null);
    }
  }, [open]);

  return (
    <div className="unassigne_content">
      <table className="table">
        <thead>
          <tr className="">
            <th className="text-start w-50">Customer</th>
            <th className="text-end"></th>
          </tr>
        </thead>
        <tbody id="data-body">
          {data.length > 0 &&
            data.map((job, index) => {
              const shiftsText = `${job.statistics.totalShiftsInJob} shift${
                job.statistics.totalShiftsInJob !== 1 ? "s" : ""
              }`;

              return (
                <React.Fragment key={job._id || index}>
                  <tr key={index}>
                    {/* Customer Column */}
                    <td className="text-start align-middle">
                      <div className="user_details d-flex align-items-center">
                        <div className="profile me-2">
                          {/* <JobStreetView job={job} /> */}
                        </div>
                        <div className="details">
                          <h4 className="name mb-1">
                            {job.customer_company_id.companyFriendlyName}
                          </h4>
                          <p className="address text-muted mb-0">
                            {job.formattedAddress}
                          </p>
                        </div>
                      </div>
                    </td>

                    <td
                      className="text-end align-middle"
                      style={{ padding: "8px 12px" }}
                    >
                      {job.statistics.offerCounts.disputed > 0 ? (
                        <div className="d-flex flex-column align-items-end">
                          {/* Disputed Badge */}
                          <div className="d-flex">
                            <span
                              style={{
                                display: "inline-flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "20px", 
                                height: "20px",
                                borderRadius: "50%",
                                backgroundColor: "#CC457D",
                                color: "white",
                                fontSize: "12px",
                                fontWeight: "bold",
                                marginRight: "5px", 
                              }}
                            >
                              {job.statistics.offerCounts.disputed}
                            </span>
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "#CC457D",
                              }}
                            >
                              Disputed Offers
                            </span>
                          </div>

                          <Button
                            size="xs"
                            appearance="link"
                            onClick={() =>
                              console.log("Navigate to dispute resolution page")
                            }
                            style={{
                              fontSize: "12px",
                              textDecoration: "underline",
                            }}
                          >
                            <Link
                              to={`/provider-company/${providerCompanyId}/jobs/${job._id}/offer`}
                              className="send_offer_btn"
                            >
                              Resolve Now
                            </Link>
                          </Button>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-end">
                            <span style={{ fontSize:"14px" , fontStyle: "inherit" }}> No Disputed Offers </span>
                        </div>
                      )}
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
        </tbody>
      </table>

      {/* <SideDrawer isOpen={open} setIsOpen={setOpen} getByItem={getByItem} /> */}
    </div>
  );
};

export default UnassignedListView;
