import React, { useEffect, useState } from "react";
import { Drawer, SelectPicker, Steps, Button } from "rsuite";
import "../../../../styles/sideDrawer.css";
import { Fragment } from "react";
import { Badge } from "@mui/material";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom";
import SendWhiteIcon from "../../../../../_asset/img/send-white_icon.svg";
import Document from "../../../../../_asset/img/docu.svg";
import Paperclip from "../../../../../_asset/img/paperclip-2.png";
import InfoCircle from "../../../../../_asset/img/info-circle.svg";
import TimerStart from "../../../../../_asset/img/timer-start.svg";
import { ReactComponent as Profile } from "../../../../../_asset/img/profile.svg";
import { getJobCompletedOffers } from "../../../../../../services/staffingService";

import {
  postJobComment,
  getCommentsForJob,
  getTeamManagers,
  assignJob,
} from "../../../../../../services/staffingService";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast, Toaster } from "react-hot-toast";

import OfficerPayout from "./officerPayout";

const schema = yup
  .object({
    comment: yup
      .string()
      .required("comment is required")
      .max(3200, "Max length exceeded"),
  })
  .required();

const SideDrawer = ({ job, isOpen, setIsOpen, getByItem }) => {
  const { providerCompanyId } = useParams();
  const [offers, setOffers] = useState([]);

  const [comments, setComments] = useState([]);
  const [selectedManager, setSelectedManager] = useState(null);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [dispatchManagers, setDispatchManagers] = useState([]);
  const [countdown, setCountdown] = useState(5);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    await postJobComment(providerCompanyId, job._id, data.comment);
    const resp = await getCommentsForJob(providerCompanyId, job._id);
    setComments([...resp.data.comments]);
    reset();
  };

  useEffect(() => {
    fetchComments();
    fetchProviderManagers();
  }, [job]);

  async function fetchComments() {
    const resp = await getCommentsForJob(providerCompanyId, job._id);
    setComments([...resp.data.comments]);
  }

  async function fetchProviderManagers() {
    const resp = await getTeamManagers(providerCompanyId);

    const formattedData = resp.data.providerManagers.map((item) => ({
      label: `${item.user_id.firstName} ${item.user_id.lastName}`,
      value: item,
    }));
    setDispatchManagers(formattedData);
  }

  const handleSelectManager = (value) => {
    setSelectedManager(value);
    setShowSaveButton(true);
  };

  const handleSave = async () => {
    if (selectedManager) {
      await assignJob(providerCompanyId, job._id, selectedManager._id);
      setShowSaveButton(false);

      const toastId = toast.success(
        `Dispatch Manager assigned. Refreshing page in ${countdown} seconds...`,
        {
          duration: 5000,
        }
      );

      const countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownInterval);
            window.location.reload();
            return 0;
          }
          const newCountdown = prevCountdown - 1;
          toast.dismiss(toastId);
          toast.success(
            `Dispatch Manager assigned. Refreshing page in ${newCountdown} seconds...`,
            {
              id: toastId,
              duration: newCountdown * 1000,
            }
          );
          return newCountdown;
        });
      }, 1000);
    }
  };

  const handleClearManager = () => {
    setSelectedManager(null);
    setShowSaveButton(false);
  };

  useEffect(() => {
    fetchCompletedJobOffers();
  }, []);

  const fetchCompletedJobOffers = async () => {
    const resp = await getJobCompletedOffers(providerCompanyId, job._id);
    setOffers([...resp.data.offers]);
    console.log("Completed job shift offers per officer:", resp.data);
  };

  const groupShiftsByOfficer = (offers) => {
    const grouped = {};

    offers.forEach((offer) => {
      const officer = offer.provider_resource_id.user_id;
      const officerName = `${officer.firstName} ${officer.lastName}`;

      if (!grouped[officerName]) {
        grouped[officerName] = {
          profilePicture: officer.profilePictureUrl,
          email: officer.email,
          shifts: [],
          totalPayout: 0,
          status: offer.status, // ✅ Store the status
        };
      }

      offer.shifts.forEach((shift) => {
        const shiftDurationHours =
          (new Date(shift.endTime) - new Date(shift.startTime)) /
          (1000 * 60 * 60);

        shift.payout =
          shiftDurationHours * offer.negotiations.providerHourlyRate;
        grouped[officerName].totalPayout += shift.payout;

        grouped[officerName].shifts.push(shift);
      });
    });

    return grouped;
  };

  const groupedShifts = groupShiftsByOfficer(offers);

  const calculateTotalChargeForCustomer = (job) => {
    const hourlyPayRate = job.hourlyPayRate; // The agreed rate customer pays per hour per staff
    let totalCharge = 0;

    // Iterate through all shifts in the job
    job.shifts.forEach((shift) => {
      const shiftDurationHours =
        (new Date(shift.endTime) - new Date(shift.startTime)) /
        (1000 * 60 * 60); // Convert ms to hours

      const totalStaffPresent = job.statistics.totalShiftsCovered; // Number of staff that actually worked

      // Calculate total charge for this shift
      totalCharge += shiftDurationHours * totalStaffPresent * hourlyPayRate;
    });

    return totalCharge;
  };

  const calculateTotalOfficerPayout = (offers) => {
    let totalPayout = 0;

    offers.forEach((offer) => {
      offer.shifts.forEach((shift) => {
        if (shift.payout) {
          totalPayout += shift.payout; // Sum all shift payouts
        }
      });
    });

    return totalPayout;
  };

  const totalOfficerPayout = calculateTotalOfficerPayout(offers);
  const totalChargeForCustomer = calculateTotalChargeForCustomer(job);

  return (
    <Drawer
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="side_drawer"
    >
      <Drawer.Body>
        <div className="drawer_content">
          <div className="left_bar">
            <h2 className="title">
              {" "}
              {job.customer_company_id.companyLegalName}{" "}
            </h2>
            <div className="drawer_inner_content" id="scrollBar">
              <div className="financial_summary">
                {/* Amount Charged to Customer */}
                {/* <div className="time_amount">
                  <h2 className="amount">
                    ${totalChargeForCustomer.toFixed(2)}
                  </h2>
                  <p className="description">Total Billable Amount</p>
                </div> */}

                <div className="time_amount d-flex align-items-center justify-content-between">
                  <div className="billing-info">
                    <h2 className="amount">
                      ${totalChargeForCustomer.toFixed(2)}
                    </h2>
                    <p className="description">Total Billable Amount</p>
                  </div>

                  <div className="hourly-rate d-flex flex-column ">
                    <h3 className="amount">${50} </h3>
                    <p className="description">Hourly Rate</p>
                  </div>

                  <button className="completed-offers-manage-btn">
                    Send Invoice
                  </button>
                </div>

                {/* Amount Paid to Officers */}
                <div className="time_amount">
                  <h2 className="amount">${totalOfficerPayout.toFixed(2)}</h2>
                  <p className="description">Total Payout to Officers</p>
                </div>
              </div>

              {/* Officer-Based Shift Details */}

              <OfficerPayout
                groupedShifts={groupedShifts}
                providerCompanyId={providerCompanyId}
                job={job}
              />

              {/* Offer Statistics (Unchanged) */}
              {/* <div className="shifts_card offer_card">
                <h2 className="shifts_title">Offer Statistic</h2>
                <div className="offer_items">
                  <div className="offer_item">
                    <p className="label">Available</p>
                    <p className="details">18 Officers Within 50 Miles</p>
                  </div>
                  <div className="offer_item">
                    <p className="label">Valid Offers</p>
                    <p className="details">
                      Enable VPC Flow logs for all subnets in VPC Network
                    </p>
                  </div>
                </div>
              </div> */}

              <div className="comment_content">
                <Steps current={1} vertical>
                  {comments.length >= 1 &&
                    comments.map((comment, index) => (
                      <Steps.Item
                        key={comment._id}
                        title={
                          <div className="comment_title">
                            <h4 className="comment_heading">
                              {comment.provider_manager_id &&
                              comment.provider_manager_id.user_id
                                ? `${comment.provider_manager_id.user_id.firstName} ${comment.provider_manager_id.user_id.lastName}`
                                : "Unknown Manager"}
                            </h4>
                            <ul className="other_details">
                              <li>
                                {new Date(
                                  comment.createdAt
                                ).toLocaleDateString()}
                              </li>
                            </ul>
                          </div>
                        }
                        description={
                          <Fragment>
                            <p className="description">{comment.text}</p>
                            {/* Example if there's a document associated - remove this if not applicable */}
                            {comment.attachmentUrl && (
                              <div>
                                <Badge className="document">
                                  <img src={Document} alt="Document Icon" />{" "}
                                  {comment.attachmentUrl}
                                </Badge>
                              </div>
                            )}
                          </Fragment>
                        }
                        icon={
                          <>
                            {" "}
                            <img
                              style={{
                                height: "24px",
                                width: "24px",
                                borderRadius: "24px",
                              }}
                              src={
                                comment.provider_manager_id.user_id
                                  .profilePictureUrl
                              }
                            />{" "}
                          </>
                        }
                      />
                    ))}
                </Steps>
              </div>

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="send_comment">
                  <textarea
                    rows={3}
                    placeholder="Ask AI"
                    {...register("comment")}
                  ></textarea>
                  {errors.comment && (
                    <span className="errorTextForm mt-1">
                      {" "}
                      {errors.comment.message}{" "}
                    </span>
                  )}
                  <div className="comment_btn">
                    {/* <img src={Paperclip} alt="" /> */}
                    <button className="btn">Comment</button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          {/* Right Sidebar - Assignment & Job Details (Unchanged) */}
          <div className="right_bar" id="scrollBar">
            <div>
              <h2 className="title_assi">Job Assignment</h2>
              <div className="data_card">
                <p>Assigned Dispatch Manager:</p>
                <h2>
                  {" "}
                  {job.provider_manager_id.user_id.firstName}{" "}
                  {job.provider_manager_id.user_id.lastName}{" "}
                  <img src={InfoCircle} alt="Information about the manager" />
                </h2>
                <label>Reassign Dispatch Manager:</label>
                <SelectPicker
                  data={dispatchManagers}
                  onSelect={handleSelectManager}
                  onClean={handleClearManager}
                  placeholder="Managers"
                  style={{ width: 300 }}
                />
                {showSaveButton && (
                  <Button
                    appearance="primary"
                    size="xs"
                    onClick={handleSave}
                    style={{ marginTop: "10px" }}
                  >
                    Save
                  </Button>
                )}
              </div>

              <h2 className="title_assi">Job Details</h2>
              <div className="data_card">
                <div>
                  <p>Job ID</p>
                  <h2>#{job._id}</h2>
                </div>
                <div>
                  <p>Location</p>
                  <h2>{job.location}</h2>
                </div>
                <div>
                  <p>Service Type:</p>
                  <h2>
                    {job.service
                      .split("_")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                  </h2>
                </div>
              </div>
              <h2 className="title_assi">Impact</h2>
              <div className="data_card">
                <div className="other_item">
                  <p>Revenue:</p>
                  <div className="right_col">
                    <Badge className="high badge_status">High</Badge>
                  </div>
                </div>
                <div className="other_item">
                  <p>Priority:</p>
                  <div className="right_col">
                    <Badge className="low badge_status">Low</Badge>
                  </div>
                </div>
                <div className="other_item">
                  <p>Urgency:</p>
                  <div className="right_col">
                    <Badge className="medium badge_status">Medium</Badge>
                  </div>
                </div>
                {/* <div className='other_item'>
                                    <p>SLA:</p>
                                    <div className='right_col info'><img src={TimerStart} alt="" /> <span>2 Days</span></div>
                                </div>
                                <div className='other_item'>
                                    <p>Created on</p>
                                    <div className='right_col info'>{new Date(job.createdAt).toLocaleDateString()}, {new Date(job.createdAt).toLocaleTimeString()}</div>
                                </div>
                                <div className='other_item'>
                                    <p>Created by</p>
                                    <div className='right_col info'><Profile /> <span>Alok</span></div>
                                </div>
                                <div className='other_item'>
                                    <p>Last Updated</p>
                                    <div className='right_col info'>{new Date(job.invoice.updatedAt).toLocaleDateString()}, {new Date(job.invoice.updatedAt).toLocaleTimeString()}</div>
                                </div>
                                <div className='other_item'>
                                    <p>Last Updated by</p>
                                    <div className='right_col info'><Profile /><span>Pradeep</span></div>
                                </div> */}
              </div>
            </div>

            {/* Send Offer Button (Unchanged) */}
            {/* <div className="send_offer">
              <SelectPicker
                data={[]}
                placement="topStart"
                placeholder="In process"
              />
              <Link
                to={`/provider-company/${providerCompanyId}/jobs/${job?._id}?view=recommended`}
                className="send_offer_btn"
              >
                Send Offer
                <span className="send_icon">
                  <span className="line"></span>
                  <img src={SendWhiteIcon} alt="" />
                </span>
              </Link>
            </div> */}
          </div>
        </div>
      </Drawer.Body>
    </Drawer>
  );
};

export default SideDrawer;
