import React, { useState, useEffect } from "react";
import "../../../../styles/recommendedListingItem.css"; // Same CSS to preserve design
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import StarIcon from "@mui/icons-material/Star";
import Scheduler from "../../scheduler.closest.jsx";
import { InputNumber, Tag, TagGroup, Whisper, Tooltip } from "rsuite";
import { createOffer } from "../../../../../../services/staffingService";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import { ReactComponent as InfoCircleGreen } from "../../../../../_asset/img/info-circle_green.svg";
import { ReactComponent as TaskSquare } from "../../../../../_asset/img/task-square.svg";
import { ReactComponent as Star } from "../../../../../_asset/img/star-circle.svg";
import { ReactComponent as DollarCircle } from "../../../../../_asset/img/dollar-circle.svg";
import { ReactComponent as MakeOffer } from "../../../../../_asset/img/make_offer.svg";

// Utility functions for date formatting
function formatCompactShiftDate(dateString) {
  const options = { day: "2-digit", month: "short" };
  return new Date(dateString).toLocaleDateString(undefined, options);
}

function formatFullShiftDate(dateString) {
  const options = {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  return new Date(dateString).toLocaleDateString(undefined, options);
}

const ListingDetails = ({
  providerCompanyId,
  staffs,
  hoveredStaff,
  setStaffs,
  setHoveredStaff,
  job,
  setSelectedOfferId, 
  setActiveTab,
  setOfficersCount,
  officersCount ,
  fetchJobAndOfferMetadata
  
}) => {
  const [activeStaffId, setActiveStaffId] = useState(null);

  const handleMouseEnter = (staffMember) => {
    setHoveredStaff(staffMember);
  };

  const openModal = (staffId) => {
    setActiveStaffId(staffId);
  };

  const closeModal = () => {
    setActiveStaffId(null);
  };

  const sendOffer = async (staff, job) => {
    try {

      switch (true) {
        case staff.assignedShifts && staff.assignedShifts.length > 0:
          toast.info('Shifts cannot be assigned');
          return;
  
        case staff.availableShiftsForUnassignedStaff && staff.availableShiftsForUnassignedStaff.length > 0:
          staff.assignedShifts = [...staff.availableShiftsForUnassignedStaff];
          staff.availableShiftsForUnassignedStaff = [];
          // toast.success('Shifts moved to assigned successfully.');
          break;
  
        default:
          toast.warning('No available shifts to assign.');
          return;
      }
  
      // Proceed with sending the offer after mutation
      const resp = await createOffer(providerCompanyId, staff, job);
      setStaffs((prevStaffs) => {
        return prevStaffs.map((staff) => {
          if (staff._id === resp.data.offer.provider_resource_id) {
            return {
              ...staff,
              offer: {
                ...staff.offer,
                offerId: resp.data.offer._id,
                status: resp.data.offer.status,
              },
            };
          }
          return staff;
        });
      });
  
      toast.success('Offer sent successfully');
      setActiveStaffId(null);
  
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data);
      }
    }
  };
  

  const handleChange = (value, staffId) => {
    setStaffs((prevStaffs) =>
      prevStaffs.map((s) =>
        s._id === staffId ? { ...s, offerPrice: value } : s
      )
    );
  };

  const handleTabChange = (offerId) => {
    setSelectedOfferId(offerId);
    setActiveTab("2");
    fetchJobAndOfferMetadata();
    /*
    if (setSelectedOfferId && setActiveTab) {
    }
    */
  };

  // Update useEffect to set officers count when staffs change
  useEffect(() => {
    if (staffs && staffs.length) {
      setOfficersCount(staffs.length);
    }
  }, [staffs, setOfficersCount]);

  return (
    <div className="recommended-staff-listing">
      {staffs.length > 0 && (
        <div
          className="recommended-staff-listing-listingDetails_section mr-3"
          style={{
            display: "grid",
            gridTemplateColumns: "1fr",
            gap: "16px",
            minWidth: "600px",
          }}
        >
          {staffs.map((m) => (
            <div
              key={m._id}
              className="recommended-staff-listing-view_box selected"
              onClick={() => setHoveredStaff(m)}
            >
              <div className="title d-flex justify-content-between">
                {`${m.user_id?.firstName} ${m.user_id?.lastName}`}
                <div className="available_box listing-daily_impre">
                  <span>
                    {`${(
                      ((job.hourlyPayRate - m.offerPrice) / job.hourlyPayRate) *
                      100
                    ).toFixed(0)}% Margin`}
                  </span>
                </div>
              </div>

              <div className="address mt-2">
                <LocationOnIcon />
                {`${m.formattedLocation} (${m.distanceMiles?.toFixed(
                  2
                )} miles away)`}
              </div>

              <div className="daily_update">
                <div className="daily_impre">
                  <DollarCircle />
                  {`20 jobs done in last 3 months`}
                </div>
                <div className="daily_impre daily_approx">
                  <Star />
                  {`$${m.preferences?.minHourlyRate} per hour `}
                </div>
                <div className="daily_impre daily_approx">
                  <TaskSquare />
                  4.3
                </div>
              </div>

              {/* {m.assignedShifts && m.assignedShifts.length > 0 && (
                <div className="assigned-shifts">
                  <span className="label"> Available Shifts:</span>
                  <TagGroup>
                    {m.assignedShifts.map(shift => (
                      <Whisper
                        key={shift._id}
                        placement="top"
                        trigger="hover"
                        speaker={
                          <Tooltip>
                            {`${formatFullShiftDate(shift.startTime)} Hrs - ${formatFullShiftDate(shift.endTime)} Hrs`}
                          </Tooltip>
                        }
                      >
                        <Tag>
                          {formatCompactShiftDate(shift.date)}
                        </Tag>
                      </Whisper>
                    ))}
                  </TagGroup>
                </div>
              )} */}

              <>
                {/* Show Offered Shifts if staff has an existing offer */}
                {m.offer ? (
                  <div className="assigned-shifts">
                    <span className="label">Offered Shifts:</span>
                    <TagGroup>
                      {m.assignedShifts.map((shift) => (
                        <Whisper
                          key={shift._id}
                          placement="top"
                          trigger="hover"
                          speaker={
                            <Tooltip>
                              {`${formatFullShiftDate(
                                shift.startTime
                              )} Hrs - ${formatFullShiftDate(
                                shift.endTime
                              )} Hrs`}
                            </Tooltip>
                          }
                        >
                          <Tag>{formatCompactShiftDate(shift.date)}</Tag>
                        </Whisper>
                      ))}
                    </TagGroup>
                  </div>
                ) : (
                  /* Show Available Shifts if no offer exists */
                  m.availableShiftsForUnassignedStaff &&
                  m.availableShiftsForUnassignedStaff.length > 0 && (
                    <div className="assigned-shifts">
                      <span className="label">Available Shifts:</span>
                      <TagGroup>
                        {m.availableShiftsForUnassignedStaff.map((shift) => (
                          <Whisper
                            key={shift._id}
                            placement="top"
                            trigger="hover"
                            speaker={
                              <Tooltip>
                                {`${formatFullShiftDate(
                                  shift.startTime
                                )} Hrs - ${formatFullShiftDate(
                                  shift.endTime
                                )} Hrs`}
                              </Tooltip>
                            }
                          >
                            <Tag>{formatCompactShiftDate(shift.date)}</Tag>
                          </Whisper>
                        ))}
                      </TagGroup>
                    </div>
                  )
                )}
              </>

              <hr className="hr" />

              {!m.offer && (
                <div className="hourl_rate d-flex justify-content-between">
                  <div className="info">
                    <span className="lable">Set Hourly Rate:</span>
                    <span className="selectBox" id="price-bidding">
                      $
                      <InputNumber
                        size="xs"
                        value={m.offerPrice}
                        onChange={(value) => handleChange(value, m._id)}
                      />
                    </span>
                  </div>
                  {activeStaffId === m._id && (
                    <Scheduler
                      isOpen={true}
                      onRequestClose={closeModal}
                      className="scheduler-modal"
                      overlayClassName="scheduler-modal-overlay"
                      ariaHideApp={false}
                      sendOffer={sendOffer}
                      staff={m}
                      job={job}
                    />
                  )}
                  {
                    m.availableShiftsForUnassignedStaff?.length > 0 && (
                    <div
                      onClick={() => openModal(m._id)}
                      className="make_offer_btn"
                    >
                      Make Offer <MakeOffer />
                    </div>
                    )
                  }
                </div>
              )}

              {m.offer?.offerId && (
                <div className="listing-request_info d-flex justify-content-end">
                  <div
                    className="info"
                    style={{ fontWeight: "500", cursor: "pointer" }}
                    onClick={() => {
                      handleTabChange(m.offer.offerId);
                    }}
                  >
                    {m.offer.status === "completed" && (
                      <span style={{ color: "#28a745" }}>
                        Pending Payment <ChevronRightOutlinedIcon />
                      </span>
                    )}
                    {m.offer.status === "paid" && (
                      <span style={{ color: "#28a745" }}>
                        Payment Completed <ChevronRightOutlinedIcon />
                      </span>
                    )}
                    {m.offer.status === "accepted" && (
                      <span style={{ color: "#28a745" }}>
                        Offer Accepted <ChevronRightOutlinedIcon />
                      </span>
                    )}
                    {m.offer.status === "rejected" && (
                      <span style={{ color: "#dc3545" }}>
                        Offer Rejected <ChevronRightOutlinedIcon />
                      </span>
                    )}
                    {m.offer.status === "expired" && (
                      <span style={{ color: "#dc3545" }}>
                        Offer Expired <ChevronRightOutlinedIcon />
                      </span>
                    )}
                    {m.offer.status === "open" && (
                      <span style={{ color: "#ffb400", fontSize: "13px" }}>
                        Pending Response{" "}
                        <OpenInNewIcon style={{ fontSize: "14px" }} />
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ListingDetails;
