import React, { useState, useEffect } from "react";
import {
  Steps,
  Button,
  Panel,
  Notification,
  SelectPicker,
  Table,
} from "rsuite";
import { Link } from "react-router-dom";
import "./offerStepper.css";
import NegotiationSteps from "./NegotiationSteps";
import toast from "react-hot-toast";

import {
  createCustomerCheckoutSession,
  sendPayout,
  resolveDisputedOffer,
} from "../../../../../../../services/staffingService";

function formatJobDateTime(dateTime) {
  const date = new Date(dateTime);
  return date.toLocaleString("en-US", {
    weekday: "short", // "Mon", "Tue", etc.
    year: "numeric", // "2024"
    month: "short", // "Jul"
    day: "numeric", // "31"
    hour: "2-digit", // "10 PM"
    minute: "2-digit", // "30 PM"
  });
}

const disputeReasons = [
  { definition: "No-show for scheduled shift", status: 1 },
  { definition: "Late arrival to scheduled shift", status: 2 },
  { definition: "Missing checkout at shift end", status: 3 },
  { definition: "Late check-in for shift", status: 4 },
  { definition: "Check-in or check-out outside geofence area", status: 5 },
  { definition: "Inappropriate behavior during shift", status: 6 },
];

const ActionComponent = ({ shift }) => {
  const now = new Date().getTime();
  const checkInWindowStart = new Date(shift.checkIn.timeWindow.start).getTime();
  const checkInWindowEnd = new Date(shift.checkIn.timeWindow.end).getTime();
  const checkOutWindowStart = new Date(
    shift.checkOut.timeWindow.start
  ).getTime();
  const checkOutWindowEnd = new Date(shift.checkOut.timeWindow.end).getTime();

  const renderCheckInStatus = () => {
    switch (true) {
      case shift.checkIn.confirmed:
        return `Geo Check In confirmed at ${new Date(
          shift.checkIn.timestamp
        ).toLocaleTimeString()} Hrs.`;
      case now > checkInWindowEnd:
        return "No show.";
      default:
        return `Geo Check In - Pending - ${formatJobDateTime(
          shift.checkIn.timeWindow.start
        )} Hrs.`;
    }
  };

  const renderCheckOutStatus = () => {
    switch (true) {
      case shift.checkOut.confirmed:
        return `Geo Check Out confirmed at ${new Date(
          shift.checkOut.timestamp
        ).toLocaleTimeString()} Hrs.`;
      case shift.checkIn.confirmed && now > checkOutWindowEnd:
        return "Check out window has passed.";
      case shift.checkIn.confirmed:
        return `Geo Check Out - Pending - ${formatJobDateTime(
          shift.checkOut.timeWindow.start
        )} Hrs.`;
      default:
        return "Check-in required before check-out.";
    }
  };

  return (
    <div>
      <p>{renderCheckInStatus()}</p>
      <p>{renderCheckOutStatus()}</p>
    </div>
  );
};

const WorkflowStepper = ({
  offer,
  negotiations,
  setOffer,
  setNegotiations,
  offerId,
  providerCompanyId,
  jobId,
}) => {
  const { Column, HeaderCell, Cell } = Table;
  const [dispute, setDispute] = useState(offer.dispute || {});
  const handleDisputeChange = (status) => {
    const selectedReason = disputeReasons.find((r) => r.status === status);

    if (selectedReason) {
      const updatedDispute = {
        definition: selectedReason.definition,
        status: selectedReason.status,
        details: dispute.details || "", // Preserve existing details if any
        raisedAt: dispute.raisedAt || new Date(), // Set if not already set
        resolvedBy: dispute.resolvedBy || null, // Keep null until resolved
        resolvedAt: dispute.resolvedAt || null, // Keep null until resolved
        resolutionNotes: dispute.resolutionNotes || "", // Preserve existing notes
      };
      setDispute(updatedDispute);
    }
  };

  const resolveDispute = async () => {
    try {
      const resp = await resolveDisputedOffer(
        providerCompanyId,
        offerId,
        dispute
      );

      setOffer((prevOffer) => ({
        ...prevOffer,
        status: "resolved",
        dispute: resp.data.offer.dispute,
      }));
      toast.success("Dispute resolved successfully");
      console.log("set status as resolved. .");
    } catch (error) {
      toast.error("Failed to resolve dispute. Server Error");
    }
  };

  return (
    <div className="offer-stepper-container" id="offer-stepper">
      <Steps current={offer.overallActiveStep} vertical>
        {/* Step 1: Negotiation */}
        <Steps.Item
          title="Negotiation"
          description={
            <NegotiationSteps
              negotiations={negotiations}
              setNegotiations={setNegotiations}
              offer={offer}
              setOffer={setOffer}
              offerId={offerId}
              providerCompanyId={providerCompanyId}
              jobId={jobId}
            />
          }
        />

        {/* Step 2: Job Status (Always Visible, Details Show Only When Active) */}
        <Steps.Item
          title="Job Status"
          description={
            <>
              {offer.overallActiveStep >= 1 && (
                <>
                  <Steps
                    current={
                      offer.status === "disputed"
                        ? 1
                        : offer.status === "resolved"
                        ? 2
                        : offer.status === "accepted"
                        ? 0
                        : 1
                    }
                    vertical
                  >
                    {/* Sub-Step 1: Shift Processing */}
                    <Steps.Item
                      title="Shifts"
                      // status="finish"
                      description={
                        // <div key={index} className="shift-action">
                        //   <ActionComponent shift={shift} />
                        // </div>
                        <Table
                          data={offer.shifts.map((shift, index) => ({
                            shiftDate: new Date(
                              shift.date
                            ).toLocaleDateString(),
                            checkInTime: shift.checkIn.timestamp
                              ? new Date(
                                  shift.checkIn.timestamp
                                ).toLocaleTimeString()
                              : "Not Checked-in",
                            checkOutTime: shift.checkOut.timestamp
                              ? new Date(
                                  shift.checkOut.timestamp
                                ).toLocaleTimeString()
                              : "Not Checked-out",
                            cityRowSpan: index === 0 ? offer.shifts.length : 0,
                          }))}
                          autoHeight
                          bordered
                          cellBordered
                          headerHeight={30}
                          rowHeight={40}
                          fluid
                          style={{ width: "100%", fontSize: "12px" }}
                          className="mt-2"
                        >
                          <Column flexGrow={1}>
                            <HeaderCell>Date</HeaderCell>
                            <Cell dataKey="shiftDate" />
                          </Column>

                          <Column flexGrow={1}>
                            <HeaderCell>Check-in</HeaderCell>
                            <Cell>
                              {(rowData) => (
                                <span
                                  style={{
                                    color: rowData.checkInTime.includes("Not")
                                      ? "#d9534f"
                                      : "#5cb85c",
                                  }}
                                >
                                  {rowData.checkInTime}
                                </span>
                              )}
                            </Cell>
                          </Column>

                          <Column flexGrow={1}>
                            <HeaderCell>Check-out</HeaderCell>
                            <Cell>
                              {(rowData) => (
                                <span
                                  style={{
                                    color: rowData.checkOutTime.includes("Not")
                                      ? "#d9534f"
                                      : "#5cb85c",
                                  }}
                                >
                                  {rowData.checkOutTime}
                                </span>
                              )}
                            </Cell>
                          </Column>
                        </Table>
                      }
                    />

                    {/* Sub-Step 2: Dispute Resolution (Only if disputed) */}
                    {offer.status === "disputed" && (
                      <Steps.Item
                        title="Dispute Resolution"
                        description={
                          <div className="dispute-resolution">
                            <p>
                              <span className="dispute-warning">
                                {" "}
                                <strong>⚠ Dispute Raised:</strong>
                              </span>{" "}
                              select dispute reason -
                            </p>

                            {/* Flexbox container for alignment */}
                            <div className="dispute-action-container">
                              <SelectPicker
                                size="xs"
                                data={disputeReasons.map((r) => ({
                                  label: r.definition, // Display the definition
                                  value: r.status, // Use status as the value
                                }))}
                                value={dispute.status || null}
                                onChange={(value) => handleDisputeChange(value)}
                                style={{
                                  width: "320px",
                                }}
                                placeholder="Select Reason"
                                cleanable={false}
                                searchable={false}
                              />

                              {/* Resolve Dispute Button */}
                              <button
                                className="resolve-button"
                                onClick={() => resolveDispute(offer.id)}
                              >
                                Resolve Dispute
                              </button>
                            </div>
                          </div>
                        }
                      />
                    )}

                    {offer.status === "resolved" && (
                      <Steps.Item
                        title="Dispute Resolved"
                        description={
                          <div className="dispute-resolution">
                            <p>
                              <strong>✅ Dispute Reason:</strong>{" "}
                              {offer.dispute.definition}
                            </p>
                            <p>
                              <strong>Resolved At:</strong>{" "}
                              {new Date(
                                offer.dispute.resolvedAt
                              ).toLocaleString()}
                            </p>
                            {/* <p>
                              <strong>Resolved By:</strong>{" "}
                              {offer.dispute.resolvedBy || "Unknown Manager"}
                            </p> */}
                          </div>
                        }
                      />
                    )}
                  </Steps>
                </>
              )}
            </>
          }
        />

        {/* Step 3: Payment (Always Visible, Details Show Only When Active) */}
        <Steps.Item
          title="Payment"
          description={
            <>
              {offer.overallActiveStep >= 2 && (
                <div className="payment-info">
                  <p>
                    {offer.jobId.invoice.status === "paid" ? (
                      <>
                        Payment successfully received from{" "}
                        {offer.jobId.customerName}. <br />
                        <a
                          href={offer.jobId.invoice.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="receipt-link"
                        >
                          View receipt
                        </a>
                      </>
                    ) : (
                      <>
                        Invoice issued to {offer.jobId.customerName}. <br />
                        Current payment status -
                        <strong
                          className={`payment-status ${offer.jobId.invoice.status}`}
                        >
                          {offer.jobId.invoice.status}
                        </strong>
                      </>
                    )}
                  </p>
                </div>
              )}
            </>
          }
        />
      </Steps>
    </div>
  );
};

export default WorkflowStepper;
