import React, { useState, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Dialog, DialogActions, DialogContent, 
    DialogContentText, DialogTitle, FormControlLabel, Typography } from '@mui/material';
import '../../../../styles/listingsidebar.css';
import FilterListIcon from '@mui/icons-material/FilterList';
import { RangeSlider } from 'rsuite';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DeleteIcon from '@mui/icons-material/Delete';
import StarIcon from '@mui/icons-material/Star';
import AddIcon from '@mui/icons-material/Add';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

export default function ListingSidebar({ all, staffs, setStaffs }) {
    const [priceRange, setPriceRange] = useState([0, 200]);
    const [distanceRange, setDistanceRange] = useState([0, 100]); // new distance range
    const [impressions, setImpressions] = useState([25, 300]);
    const [startDate, setStartDate] = useState(new Date());
    const [serviceType, setServiceType] = useState([]); // renamed from billboardType
    
    const checkBoxItems = Array.from(new Set(all.flatMap(i => i.servicesOffered)));
    const [seeAll, setSeeAll] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleChange = (service) => {
        const updatedServiceType = serviceType.includes(service)
            ? serviceType.filter(s => s !== service)
            : [...serviceType, service];
        
        setServiceType(updatedServiceType);
        updateStaffList(updatedServiceType);
    };

    const updateStaffList = () => {
        let filtered = all;

        // Filter by service type
        if (serviceType.length) {
            filtered = filtered.filter(staff => staff.servicesOffered.some(service => serviceType.includes(service)));
        }

        // Filter by price range
        filtered = filtered.filter(staff => staff.preferences?.minHourlyRate >= priceRange[0] && staff.preferences?.minHourlyRate <= priceRange[1]);

        // ✅ Filter by distanceMiles
        filtered = filtered.filter(staff => 
            staff.distanceMiles >= distanceRange[0] && staff.distanceMiles <= distanceRange[1]
        );

        setStaffs(filtered);
    };

    useEffect(() => {
        updateStaffList();
    }, [serviceType, priceRange, distanceRange]);

    return (
        <>
            <div className='listing-sidebar-section'>
                <ul className='sidebar-items'>

                    {/* Existing filters here */}

                    <li className='sidebar-item'>
                        <div className="item-title">
                            <h2>Hourly Charge</h2>
                            <h3 className='range'>${priceRange[0]} - ${priceRange[1]}</h3>
                            <RangeSlider defaultValue={priceRange} min={30} max={75} onChange={(newRange) => setPriceRange(newRange)} />
                        </div>
                    </li>

                    {/* ✅ NEW DISTANCE FILTER */}
                    <li className='sidebar-item'>
                        <div className="item-title">
                            <h2>Distance (miles)</h2>
                            <h3 className='range'>{distanceRange[0]} - {distanceRange[1]} mi</h3>
                            <RangeSlider
                                defaultValue={distanceRange}
                                min={0}
                                max={100}
                                step={1}
                                onChange={(newRange) => setDistanceRange(newRange)}
                            />
                        </div>
                    </li>

                    {/* Other existing sidebar items untouched */}
                </ul>
            </div>
        </>
    );
}
