import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import DeleteIcon from '@mui/icons-material/Delete';
import { addFileToProviderResourceByOfficer, removeFileFromProviderResourceByOfficer } from '../../../../services/staffingService';

const DocumentsTab = ({ providerResource, providerCompanyId, setProviderResource }) => {

    const [uploadedFile, setUploadedFile] = useState(null);
    const [documentType, setDocumentType] = useState("");
    const documentTypes = ["Contract", "Service Agreement", "License", "Certificate", "Other"];

    const handleRemoveExistingFile = async (fileId) => {
        try {
            await removeFileFromProviderResourceByOfficer(providerCompanyId, providerResource._id, fileId);
            setProviderResource((prevData) => {
                const updatedFiles = (prevData.files || []).filter((file) => file._id !== fileId);
                return { ...prevData, files: [...updatedFiles] };
            });

            console.log(`File removed successfully: ${fileId}`);
        } catch (error) {
            console.error("Error removing file:", error);
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
                setUploadedFile(acceptedFiles[0]);
            }
        },
        accept: 'application/pdf',
    });

    const handleSave = async () => {
        if (uploadedFile && documentType) {
            try {
                const response = await addFileToProviderResourceByOfficer(providerCompanyId, providerResource._id, uploadedFile);
                
                if (response && response.data) {
                    const newFile = {
                        _id: response.data._id,
                        fileType: documentType,
                        fileName: uploadedFile.name,
                        fileUrl: response.data.fileUrl,
                        uploadDate: new Date(),
                    };

                    // Ensure we create a new array reference with the added file
                    setProviderResource((prevData) => {
                        const updatedFiles = [...(prevData.files || []), newFile];
                        return { ...prevData, files: updatedFiles };
                    });

                    setUploadedFile(null);
                    setDocumentType("");
                    console.log("File successfully uploaded");
                }
            } catch (error) {
                console.error("Error saving file:", error);
            }
        } else {
            console.log("No file or document type selected");
        }
    };

    return (
        <div className="tabs__content" id="scrollBar" style={{ padding: '20px' }}>
            <input {...getInputProps()} />

            <div>
                {providerResource.files && providerResource.files.length > 0 ? (
                    <div className="existing-files-list" style={{ marginBottom: '20px' }}>
                        {providerResource.files.map((file) => (
                            <div key={file._id} className="existing-file" style={{ marginBottom: '10px' }}>
                                <a
                                    href={file.fileUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#007BFF',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {file.fileType} - {file.fileName}
                                </a>
                                <DeleteIcon
                                    onClick={() => handleRemoveExistingFile(file._id)}
                                    style={{
                                        cursor: 'pointer',
                                        marginLeft: '10px',
                                        color: '#FF4D4F',
                                        verticalAlign: 'middle',
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                ) : (
                    <p>No documents found.</p>
                )}
            </div>

            {!uploadedFile && (
                <div
                    {...getRootProps()}
                    className="dropzone mt-5"
                    style={{
                        border: '2px dashed #007BFF',
                        borderRadius: '8px',
                        padding: '40px',
                        textAlign: 'center',
                        color: '#007BFF',
                        backgroundColor: '#f9f9f9',
                        cursor: 'pointer',
                    }}
                >
                    <input {...getInputProps()} />
                    <p style={{ margin: '0', fontSize: '16px' }}>
                        Drag and drop a document here, or click to select one.
                    </p>
                </div>
            )}

            {uploadedFile && (
                <div style={{ marginTop: '20px', border: '1px solid #ddd', borderRadius: '8px', padding: '20px' }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '10px',
                        }}
                    >
                        <p style={{ margin: 0, fontSize: '16px', color: '#333', fontWeight: 'bold' }}>
                            {uploadedFile.name}
                        </p>
                        <DeleteIcon
                            onClick={() => setUploadedFile(null)}
                            style={{
                                cursor: 'pointer',
                                color: '#FF4D4F',
                            }}
                        />
                    </div>

                    <div style={{ marginTop: '10px' }}>
                        <label
                            htmlFor="documentType"
                            style={{
                                display: 'block',
                                fontSize: '16px',
                                fontWeight: '500',
                                marginBottom: '10px',
                                color: '#333',
                            }}
                        >
                            Select file type to save:
                        </label>
                        <select
                            id="documentType"
                            value={documentType}
                            onChange={(e) => setDocumentType(e.target.value)}
                            style={{
                                width: '100%',
                                padding: '12px',
                                borderRadius: '8px',
                                border: '1px solid #ccc',
                                fontSize: '14px',
                                color: '#333',
                            }}
                        >
                            <option value="">--Select Type--</option>
                            {documentTypes.map((type) => (
                                <option key={type} value={type}>
                                    {type}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            )}

            {uploadedFile && documentType && (
                <button
                    className="staffing-btn confirm-btn"
                    style={{
                        marginTop: '20px',
                        padding: '10px 20px',
                        backgroundColor: '#007BFF',
                        border: 'none',
                        borderRadius: '8px',
                        color: '#fff',
                        cursor: 'pointer',
                        fontSize: '16px',
                    }}
                    onClick={handleSave}
                >
                    Save Document
                </button>
            )}
        </div>
    );
};

export default DocumentsTab;
