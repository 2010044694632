import React from "react";
import moment from "moment";
import "./notifications.css";
import { getNotifications, markAllNotificationsAsRead } from "../../services/notificationService";





const Notifications = ({
  notifs = [],
  setNotifs = () => {},
  unreadCount = 0,
  setUnreadCount = () => {},
  providerCompanyId,
}) => {
  
  const handleRead = async () => {
    // Update all notifications as read
    const updatedNotifs = notifs.map((notif) => ({
      ...notif,
      readBy: [{ user: "dummyUserId", read: true }],
    }));

    // Update the state
    setNotifs(updatedNotifs);
    setUnreadCount(0); // Reset unread count

    // Call the markAllNotificationsAsRead service
    try {
      await markAllNotificationsAsRead(providerCompanyId);
    } catch (error) {
      console.error("Failed to mark notifications as read:", error);
    }
  };

  return (
    <div className="staffing-notifications-dropdown">
      <div className="staffing-notifications-header">
        <h2>Notifications</h2>
        {unreadCount > 0 && (
          <button className="staffing-notifications-new">{unreadCount} New</button>
        )}
      </div>
      <div className="staffing-notifications-list">
        {notifs.length === 0 ? (
          <p className="staffing-notifications-empty">
            No notifications.
          </p>
        ) : (
          notifs.map((notif, index) => {
            const isRead = notif.readBy?.some(
              (readByUser) => readByUser.user === "dummyUserId" && readByUser.read
            );

            return (
              <li
                key={notif._id || index}
                className={`staffing-notifications-item ${
                  isRead ? "read" : "unread"
                }`}
              >
                <div className="staffing-notifications-content">
                  <h3>{notif.title}</h3>
                  <p>{notif.message}</p>
                  <p className="staffing-notifications-time">
                    {moment(notif.createdAt).fromNow()}
                  </p>
                </div>
              </li>
            );
          })
        )}
      </div>
      {notifs.length > 0 && (
        <div className="staffing-notifications-footer">
          <button
            onClick={handleRead}
            className="staffing-notifications-mark-read"
          >
            Mark all as Read
          </button>
        </div>
      )}
    </div>
  );
};

export default Notifications;
