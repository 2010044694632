import React, { useEffect, useState } from "react";
import {
  Link,
  useParams,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import ListingDetails from "./listingitem.jsx";
import RecommendedListingDetails from "./recommendedListingItem.jsx";
import ListingDetailsMap from "./listingmap.js";
import ListingSidebar from "./listingsidebar.js";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import {
  getRecommendation,
  getOfficers,
} from "../../../../../../services/staffingService";

import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PaidIcon from "@mui/icons-material/Paid";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import { Dropdown } from "rsuite";
import JobStatistics from "./job_statistics/statistics.job";
import "../../../../styles/listing.css";
import DashboardSidebar from "../../../../../common/dashboardSidebar.jsx";
import Header from "../../../../../common/header.jsx";

const Listing = ({
  providerCompanyId,
  jobId,
  listViewType,
  setListViewType,
  setActiveTab,
  setSelectedOfferId,
  setOfficersCount,
  officersCount,
  fetchJobAndOfferMetadata
}) => {
  const [staffs, setStaffs] = useState([]);
  const [all, setAll] = useState([]);
  const [job, setJob] = useState({});
  const [hoveredStaff, setHoveredStaff] = useState({});
  const [loading, setLoading] = useState(true);
  const [exception, setException] = useState(false);
  const [positionsFilled, setPositionsFilled] = useState(false);
  const [showSideDrawer, setShowSideDrawer] = useState(false);
  const locationParam = useLocation();
  const query = new URLSearchParams(locationParam.search);
  const view = query.get("view");

  const history = useHistory();

  const handleNavigation = (viewType) => {
    setListViewType(viewType);
  };

  // ✅ Fetch Data on Mount & When Dropdown Changes
  useEffect(() => {
    fetchData(listViewType);
  }, [listViewType]);

  useEffect(() => {
    fetchData(listViewType);
  }, []);

  const fetchData = async (viewType) => {
    setLoading(true);
    try {
      let data;
      if (viewType === "recommended") {
        const response = await getRecommendation(providerCompanyId, jobId);
        data = response.data;
      } else {
        const response = await getOfficers(providerCompanyId, jobId);
        data = response.data;
      }

      if (data) {
        setOfficersCount(data.staffs.length);
        const staffsWithOffersField = data.staffs.map((staff) => ({
          ...staff,
          offerPrice: Math.round(data.job.hourlyPayRate * (1 - 0.35)),
          customerRatePercentage: 35,
          offerId: staff.offerId || null,
        }));

        const acceptedOffers = staffsWithOffersField.filter(
          (staff) =>
            staff.offer &&
            (staff.offer.status === "accepted" ||
              staff.offer.status === "completed" ||
              staff.offer.status === "paid")
        );
        
        const totalStaffsRequired = data.job.staffsQty;

        if (acceptedOffers.length >= totalStaffsRequired) {
          setStaffs(acceptedOffers);
          setAll([...acceptedOffers]);
          setJob({ ...data.job });
          setPositionsFilled(true);

          return;
        } else {
          setStaffs(staffsWithOffersField);
          setAll([...staffsWithOffersField]);
          setJob({ ...data.job });
        }
      }
    } catch (error) {
      console.error("Error Fetching Data:", error);
    }
    setLoading(false);
  };

  if (exception) return <Redirect to="/404" />;

  return (
    <div className="main_layout_content">
      <div className="main_layout_content_inner">
        <div className="send_offer_page" id="scrollBar">
          {/* ✅ FIX: Ensure UI does not break if data is loading */}
          {loading && <div className="loading-message">Loading data...</div>}

          {/* ✅ FIX: Correct Conditional Rendering Logic */}
          {!loading && staffs.length > 0 && Object.keys(job).length > 0 && (
            <>
              {positionsFilled && (
                <div className="sticky-header-message text-center">
                  <div
                    className="alert alert-danger p-2"
                    role="alert"
                    style={{ marginBottom: "0px" }}
                  >
                    <span>
                      All positions are currently filled. No further offers can
                      be sent at this time.
                    </span>
                  </div>
                </div>
              )}

              <div id="listing-billboard">
                <div className="listing-section" id="scrollBar">
                  {listViewType === "closest" && (
                    <div className="custom-container">
                      <div className="listing-section-header text-center">
                        <div className="sort_by align-items-center">
                          <div className="sort_by_item">
                            <span className="sort_by_item_span">
                              {" "}
                              <LocationOnIcon
                                style={{ fontSize: "18px" }}
                              />{" "}
                            </span>{" "}
                            {job.formattedAddress}
                          </div>
                          {
                            <div className="sort_by_item ml-3">
                              <span className="sort_by_item_span">
                                {" "}
                                <LocalPoliceIcon
                                  style={{ fontSize: "18px" }}
                                />{" "}
                              </span>{" "}
                              <b>{staffs.length} officers</b> within 100 miles{" "}
                            </div>
                          }
                          {
                            <div className="sort_by_item ml-3">
                              <span className="sort_by_item_span">
                                {" "}
                                <PaidIcon style={{ fontSize: "18px" }} />{" "}
                              </span>{" "}
                              <b>${job.hourlyPayRate}</b> per hour
                            </div>
                          }
                          <div className="sort_by_item ml-3 mr-3">
                            <JobStatistics
                              providerCompanyId={providerCompanyId}
                              jobId={job._id}
                              statistics={job.statistics}
                              location={job.formattedAddress}
                            />
                          </div>
                          <div className="sort_by_item ml-3 ">
                            <Dropdown
                              title={
                                <span
                                  style={{ fontSize: "12px", color: "#828282" }}
                                >
                                  {" "}
                                  {listViewType.charAt(0).toUpperCase() +
                                    listViewType.slice(1)}{" "}
                                </span>
                              }
                            >
                              {listViewType !== "closest" && (
                                <Dropdown.Item
                                  onClick={() => handleNavigation("closest")}
                                >
                                  <span
                                    style={{
                                      fontWeight: 500,
                                      fontSize: "13px",
                                      color: "#828282!important",
                                    }}
                                  >
                                    {" "}
                                    Closest{" "}
                                  </span>
                                </Dropdown.Item>
                              )}
                              {listViewType !== "recommended" && (
                                <Dropdown.Item
                                  onClick={() =>
                                    handleNavigation("recommended")
                                  }
                                  style={{
                                    fontWeight: 500,
                                    fontSize: "13px",
                                    color: "#828282",
                                  }}
                                >
                                  Recommended
                                </Dropdown.Item>
                              )}
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                      <div className="custom-row mt-2">
                        <div className="sideBar">
                          <ListingSidebar
                            staffs={staffs}
                            all={all}
                            setStaffs={setStaffs}
                          />
                        </div>
                        <div className="listing-content">
                          <ListingDetails
                            providerCompanyId={providerCompanyId}
                            staffs={staffs}
                            setStaffs={setStaffs}
                            job={job}
                            hoveredStaff={hoveredStaff}
                            setHoveredStaff={setHoveredStaff}
                            setSelectedOfferId={setSelectedOfferId}
                            setActiveTab={setActiveTab}
                            setOfficersCount={setOfficersCount}
                            officersCount={officersCount}
                            fetchJobAndOfferMetadata={fetchJobAndOfferMetadata}
                          />
                          <ListingDetailsMap
                            staffs={staffs}
                            job={job}
                            hoveredStaff={hoveredStaff}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {listViewType === "recommended" && (
                    <div className="custom-container">
                      <div className="custom-row">
                        <div className="listing-content d-block">
                          <div className="listing-section-header text-center">
                            <div className="sort_by align-items-center">
                              <div className="sort_by_item">
                                <span className="sort_by_item_span">
                                  {" "}
                                  <LocationOnIcon
                                    style={{ fontSize: "18px" }}
                                  />{" "}
                                </span>{" "}
                                {job.formattedAddress}
                              </div>
                              {
                                <div className="sort_by_item ml-3">
                                  <span className="sort_by_item_span">
                                    {" "}
                                    <LocalPoliceIcon
                                      style={{ fontSize: "18px" }}
                                    />{" "}
                                  </span>{" "}
                                  <b>{staffs.length} officers</b> recommended{" "}
                                </div>
                              }
                              {
                                <div className="sort_by_item ml-3">
                                  <span className="sort_by_item_span">
                                    {" "}
                                    <PaidIcon
                                      style={{ fontSize: "18px" }}
                                    />{" "}
                                  </span>{" "}
                                  <b>${job.hourlyPayRate}</b> per hour
                                </div>
                              }
                              <div className="sort_by_item ml-3 mr-3">
                                <JobStatistics
                                  providerCompanyId={providerCompanyId}
                                  jobId={job._id}
                                  statistics={job.statistics}
                                  location={job.formattedAddress}
                                />
                              </div>
                              <div className="sort_by_item ml-auto">
                                {/* uncomment below to show different listing types only supporting recommendations for now  */}
                                <Dropdown
                                  title={
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        color: "#828282",
                                        padding: 10,
                                      }}
                                    >
                                      {" "}
                                      {listViewType.charAt(0).toUpperCase() +
                                        listViewType.slice(1)}{" "}
                                    </span>
                                  }
                                >
                                  {listViewType !== "closest" && (
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleNavigation("closest")
                                      }
                                    >
                                      Closest
                                    </Dropdown.Item>
                                  )}
                                  {listViewType !== "recommended" && (
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleNavigation("recommended")
                                      }
                                      style={{
                                        fontSize: "12px",
                                        color: "#828282",
                                      }}
                                    >
                                      Recommended
                                    </Dropdown.Item>
                                  )}
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                          <RecommendedListingDetails
                            className="ml-2"
                            providerCompanyId={providerCompanyId}
                            staffs={staffs}
                            setStaffs={setStaffs}
                            job={job}
                            hoveredStaff={hoveredStaff}
                            setHoveredStaff={setHoveredStaff}
                            setSelectedOfferId={setSelectedOfferId}
                            setActiveTab={setActiveTab}
                            fetchJobAndOfferMetadata={fetchJobAndOfferMetadata}
                          />
                        </div>
                        <ListingDetailsMap
                          staffs={staffs}
                          job={job}
                          hoveredStaff={hoveredStaff}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}

          {/* ✅ FIX: Show message when no data is found */}
          {!loading && staffs.length === 0 && Object.keys(job).length > 0 && (
            <div className="no-staffs-message">No staffs found</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Listing;
