import React, { useState , useEffect } from 'react';
import './statistics.job.css'; // Import the CSS styles
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ShiftPlan from './shiftplan.jsx';
// import { getShiftPlan } from '../../../../../services/staffingService';
import { getShiftPlan } from '../../../../../../../services/staffingService';

const Footer = ({ providerCompanyId={providerCompanyId}, jobId , statistics, location }) => {
    const { offerCounts, overallCoverage } = statistics;
    const [calendarIsOpen, setCalendarIsOpen] = useState(false);
    const [shiftPlanOffers, setShiftPlanOffers] = useState([]);

    useEffect(() => {
        getShiftsPlanOffers();
    }, []);

    const openShiftCalendar = () => {
        setCalendarIsOpen(true);
    };
  
    const closeShiftCalendar = () => {
        setCalendarIsOpen(false);
    };

    const getShiftsPlanOffers = async () => {
        const { data } = await getShiftPlan(providerCompanyId, jobId);
        setShiftPlanOffers(data.shiftPlan);
    }


    return (
        <div className="">
           
            

            <button className="job-status-footer-button sort_by_item" onClick={openShiftCalendar}   >
                {/* Shift Plan */}
                <span className=""><CalendarMonthIcon style={{ fontSize:'14px' , color: '#db2677'}} className="mr-1 ml-1"/></span>
                <span style={{ fontSize:'12px' , color: "#000" }} >Shift Plan</span>
            </button>

            {calendarIsOpen && (

                <ShiftPlan
                    shiftPlan={shiftPlanOffers}
                    calendarIsOpen={calendarIsOpen}
                    closeShiftCalendar={closeShiftCalendar}
                    className='scheduler-modal'
                    overlayClassName='scheduler-modal-overlay'
                    ariaHideApp={false}
                    location={location}
                />
            )}
        </div>
    );
};


export default Footer;

/*
older version fopoter on the recommendations page 

        <div className="job-status-footer">
           
            <div className="job-status-footer-kpis">
                <div className="job-status-footer-kpi">
                    <span className="job-status-footer-count">{offerCounts.open}</span>
                    <span className="job-status-footer-label">Open Offers</span>
                </div>
                <div className="job-status-footer-kpi">
                    <span className="job-status-footer-count">{offerCounts.accepted}</span>
                    <span className="job-status-footer-label">Accepted Offers</span>
                </div>
                <div className="job-status-footer-kpi">
                    <span className="job-status-footer-count">{(overallCoverage).toFixed(2)}%</span>
                    <span className="job-status-footer-label">Shift Coverage</span>
                </div>    
            
            </div>

            <button className="job-status-footer-button" onClick={openShiftCalendar}   >
                
                <span className="job-status-footer-count"><CalendarMonthIcon style={{ fontSize:'24px' , color: '#db2677'}} className="mr-1 ml-1"/></span>
                <span className="job-status-footer-label" >Shift Plan</span>
            </button>

            {calendarIsOpen && (

                <ShiftPlan
                    shiftPlan={shiftPlanOffers}
                    calendarIsOpen={calendarIsOpen}
                    closeShiftCalendar={closeShiftCalendar}
                    className='scheduler-modal'
                    overlayClassName='scheduler-modal-overlay'
                    ariaHideApp={false}
                    // providerCompanyId={providerCompanyId}
                />
            )}
        </div>

*/ 